body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.workingProcess {
  width: 100%;
  margin: auto;
  text-align: center;
  padding: 200px;
}

.ps {
  /* overflow: auto !important; */
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto
}

.addPlus a{
color: #40189D;
}

.pageAcctive{
  color: #00bbbb !important;
}

.card-body.tablere {
  width: 100%;
  overflow: auto;
}

.ps__rail-x {
  display: none;
  opacity: 0;
  transition: background-color .2s linear, opacity .2s linear;
  -webkit-transition: background-color .2s linear, opacity .2s linear;
  height: 15px;
  bottom: 0;
  position: absolute
}

.ps__rail-y {
  display: none;
  opacity: 0;
  transition: background-color .2s linear, opacity .2s linear;
  -webkit-transition: background-color .2s linear, opacity .2s linear;
  width: 15px;
  right: 0;
  position: absolute
}

.ps--active-y {
  display: block;
  background-color: transparent
}

.ps--focus>.ps__rail-x,
.ps--focus>.ps__rail-y,
.ps--scrolling-x .ps--scrolling-y,
.ps:hover>.ps__rail-x,
.ps:hover>.ps__rail-y {
  opacity: .6
}

.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-x:focus,
.ps .ps__rail-x:hover,
.ps .ps__rail-y.ps--clicking,
.ps .ps__rail-y:focus,
.ps .ps__rail-y:hover {
  background-color: #eee;
  opacity: .9
}

.ps__thumb-x {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color .2s linear, height .2s ease-in-out;
  -webkit-transition: background-color .2s linear, height .2s ease-in-out;
  height: 6px;
  bottom: 2px;
  position: absolute
}

.ps__thumb-y {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color .2s linear, width .2s ease-in-out;
  -webkit-transition: background-color .2s linear, width .2s ease-in-out;
  width: 6px;
  right: 2px;
  position: absolute
}

.ps__rail-x:focus,
.ps__rail-x:hover {
  background-color: #999;
  height: 11px
}

.ps__rail-y:focus,
.ps__rail-y:hover {
  background-color: #999;
  width: 11px
}

@supports (-ms-overflow-style:none) {
  .ps {
      overflow: auto!important
  }
}

@media screen and (-ms-high-contrast:active),
(-ms-high-contrast:none) {
  .ps {
      overflow: auto!important
  }
}

.WInLossResult p{
  display: inline;
  padding-left: 2px;
}

 

.dataTables_paginate.paging_simple_numbers.category .previous a{
  color: #40189D;
}
.dataTables_paginate.paging_simple_numbers.category .next a{
  color: #40189D;
}

.won{
  display: inline-block;
    height: 13px;
    width: 13px;
    margin-left: 5px;
  background: green;
}
.loss{
  display: inline-block;
    height: 13px;
    width: 13px;
    margin-left: 5px;
  background: red;
}

.matchicon img{
  width: 25px;
}

.SliderItems{
  margin-right: 15px;
}

.featuredCompaniesitems{
  margin-right: 15px;
}

.addPlus i{
  font-size: 35px;
}

.submitBtn{
  margin: 14px auto;
  width: 100%;
  text-align: center;
}

.dataTables_length.category{
  float: left;
}
.dataTables_filter.category{
  float: right;
}
.dropdown.category{
  display: contents;
}
.dataTables_info.category{
  float: left;
}
.dataTables_paginate.paging_simple_numbers.category{
  float: right;
}

.dataTables_paginate.paging_simple_numbers.category li{
  border: 0 !important;
  padding: 7px 16px;
  border: 0px solid #40189D !important;
  background: rgba(64, 24, 157, 0.3) !important;
  border-radius: 5rem;
  color: #40189D !important;
  font-size: 16px;
  margin: 0;
  display: inline-block;
}
.dataTables_paginate.paging_simple_numbers.category li:nth-child(1){

  background: transparent !important;
  
 
}
.dataTables_paginate.paging_simple_numbers.category li:last-child{
 
  background: transparent !important;
 
}

.dataTables_paginate.paging_simple_numbers.category li.selected{
  color: #fff !important;
  background: #40189D !important;
}

.deleteBtn{
  display: flex;
  padding: 11px 0px 22px;
  justify-content: space-evenly;
}

.filterByCat{
  width: 360px;
}


.loadingPage{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  /* background-color: #00000087; */
  background-color: white;
}


.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: .01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #FFFFFF;
  border: 1px solid #CACACA;
  border-radius: 5px;
  line-height: 25px;
  height: 50px;
  width: 100% !important;
  outline: none;
}


.logInBtn{
  border:1px solid #40189D;
  width: max-content;
  margin: 45px auto;
    padding: 2px 29px !important;
}

.loginPageLogo img{
  border-radius: 50%;
  width: 200px;
}

.statusOrder{
  position: relative;
}

.statusOrder select{
  position: absolute;
  /* background: transparent; */
  top: 14px;
    left: 0;
}


.orderStatus{
  padding: 5px;
  border: 1px solid;
}

.userProfileImg{
  width: 500px;
}


.userProfileImg img{
 width: 50px;
 height: 50px;
 border-radius: 50%;
}


.orderIDview{
  margin-bottom: 20px;
}

.orderviewMod{
  margin-bottom: 5px;
}

.GallaryImg img{
  width: 100px;
  cursor: pointer;
}


.AdminCon h3 {
  margin-top: 20px;
  font-size: 20px;

}



.searchuniversitiesandcourses{

  position: absolute;
  background-color: #40189D;
  left: -22px;
  right: 0;
 
  width: 300px;
  top: 64px;
  z-index: 99999;

}

.searchuniversitiesandcourses ul {
  text-align: start;
  color: #aaa;
}


.searchuniversitiesandcourses ul li {
  padding-left: 17px;
  display: block;
  cursor: default;
  margin: 5px 5px;
  margin-right: 0px;
}

.searchuniversitiesandcourses ul li:hover {
  background: #f2f2f2;
}